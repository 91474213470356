import * as React from 'react';
import {withRouter, RouteComponentProps} from 'react-router';

/**
 * Provides a renderprop component to get RouteProps:
 *    <RouterContext>
 *      {(routeProps) => <MyComponent {...parentprops} {...routeProps} />}
 *    </RouterContext>
 */

interface RouterContextProps extends RouteComponentProps {
  children: (props: RouteComponentProps<any>) => React.ReactElement<any>;
}

export const RouterContext = withRouter<RouterContextProps>((props) => {
  const {history, location, match, children} = props;
  return children({history, location, match});
});
