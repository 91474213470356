// Popup Modal dialog React. Typescript Components
// by Michele Ursino
// (c) 2015 by Leica Geosystem, All Rights Reserved
// ===============================================================================

// ==================================================================================

import $ from 'jquery';
import * as React from 'react';
import * as ReactDOM  from 'react-dom';
import classNames from 'classnames';
import * as _ from 'lodash';
import * as Bootstrap from 'bootstrap';

import * as site from './tvgsite';
import * as tvgUploader from './truviewUploader';

// polyfill Math.log10.  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/log10
Math.log10 = Math.log10 || function(x) {
  return Math.log(x) * Math.LOG10E;
};

declare function require(name:string);
var BootstrapSlider = require('bootstrap-slider');
var BootstrapSwitch = require('bootstrap-switch');
var ColorPicker = require('react-color').CompactPicker;
var Autosuggest = require('react-autosuggest');
[Bootstrap];

// var spectrumColorpicker = require('spectrum-colorpicker');



export const cellDim = 54;
export const panelHeaderHeight = 45;
export const panelBottomBorder = 2;
export const panelMinHeight = cellDim;

export interface StyleMap {
  [ styleName: string ] : any;
}

export function decodeSafeString( str: string ) {
  // this prevents any overhead from creating the object each time
  var element = document.createElement('div');
  str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
  str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
  element.innerHTML = str;
  str = element.textContent;
  element.textContent = '';
  return str;
}

// ================================= <Bt> =================================

export interface BtProps {
  id: string;
  icon: string | React.ReactElement<any>;
  title: string;
  onClick: () => void;
}

/**
 *
 * @internal
 * @export
 * @class Bt
 * @extends {React.Component<BtProps, {}>}
 */
export class Bt extends React.Component< BtProps, {} > {
  constructor( p: BtProps) {
    super(p);
    this.state = {};
  }

  render() {
    const { icon, title, onClick, id } = this.props;
    return (
      <div id={id} className="tvg-button" title={title} onClick={onClick}>
        {typeof icon === 'string' ?
          <i className={`fa tvg-button-icon ${icon}`}></i> :
          icon
        }
      </div>
    );
  }
}

// ================================= <BtMenu> =================================

interface BtMenuProps {
  id: string;
  icon?: string;
  children?: any;
  preventClosing?: boolean;
  onChange?: ( openValue: boolean ) => void;
}

interface BtMenuState {
  open? : boolean;
  preventClosing?: boolean;
}

/**
 *
 * @internal
 * @export
 * @class BtMenu
 * @extends {React.Component<BtMenuProps, BtMenuState>}
 */
export class BtMenu extends React.Component<BtMenuProps,BtMenuState> {

  constructor( p: BtMenuProps) {
    super(p);
    this.state = {
      open:false,
      preventClosing: p.preventClosing
    };
  }

  componentWillReceiveProps( p : BtMenuProps ) {
    const { id } = this.props;
    this.setState({ preventClosing : p.preventClosing });
  }

  componentDidMount() {
    const { id, onChange } = this.props;
    const self = this;
    const $menuBt = $(`#${id}-BtMenu`);

    // Intercept bootstrap hide event to prevent the menu from hiding when the
    // state.preventClosing is set to true.
    $menuBt.on('hide.bs.dropdown', function (e) {
      if ( self.state.preventClosing ) {
        // reset the preventClosing to false.
        self.setState({ preventClosing: false });
        return false;
      }
      else {
        // Allow hiding to occur
        if ( onChange )
          onChange(false);
        return true;
      }
    });
    $menuBt.on('show.bs.dropdown', function (e) {
      const $backdrop = $(this).children('.dropdown-backdrop');
      // $backdrop.on( 'click', function(e) {
      //   console.log('click on bg!');
      // });
      $backdrop.on( 'dragover', function(e) {
        console.log('Dragging a file on the backdrop...!');
        $(this).dropdown('toggle');
      });
      if ( onChange )
        onChange(true);
    });

  }

  render() {
    const { id, children, icon } = this.props;
    let iconClass = classNames({
      'fa': true,
      'tvg-button-icon': true,
      'dropdown-toggle': true
    });
    const menuClass = classNames({
      'tvg-button': true,
      'dropdown' : true
    });
    if ( icon && icon.length > 0 ) {
      iconClass += ` ${icon}`;
    }
    else {
      iconClass += ' fa-bars';
    }

    // const bdrop = this.state.open ? <div className="dropdown-backdrop" id={`${id}-bdrop`} onClick={this.handleClick.bind(this)} ></div> : <span/>;

    return <div className={menuClass} id={`${id}-BtMenu`} >
      <i className={iconClass} id={id} data-toggle="dropdown" aria-haspopup="true"></i>
      <ul className="dropdown-menu dropdown-menu-right" aria-labelledby={id} id={`${id}-menu`} >
        {children}
      </ul>
    </div>;
  }
}

// ================================= <BtMenuItem> =================================

interface BtMenuItemProps {
  label: string;
  key: string;
  icon?: string;
  active?: boolean;
  onSelect: ( v: string ) => void;
}

/**
 *
 * @internal
 * @export
 * @class BtMenuItem
 * @extends {React.Component<BtMenuItemProps, {}>}
 */
export class BtMenuItem extends React.Component< BtMenuItemProps,{} > {
  constructor( p: BtMenuItemProps) {
    super(p);
    this.state = {};
  }

  render() {
    const { onSelect, label, active, icon } = this.props;
    const itemClass = classNames({
      'dropdown-menu-item': true,
      'active': active
    });
    let iconHtml = <span/>;
    if ( icon ) {
      iconHtml = <i className={`fa ${icon}`} aria-hidden="true"></i>;
    }
    return <li className={itemClass}>
      <a onClick={ (e) => { e.preventDefault(); onSelect(label); } }>
         {iconHtml} {label}
      </a>
    </li>;
  }
}

// ===================================== Pager ======================================

/**
 * @internal
 */
interface PagerProps {
  start: number;
  count: number;
  total: number;
  itemsInPage?: number;
  onLoadPage: ( p:number) => void;
  classExtra?: string;
}

/**
 * @internal
 */
export class Pager extends React.Component< PagerProps, {} > {
  constructor(p : PagerProps) {
    super(p);
    this.state = {
      nextstart : this.props.start + this.props.count,
      prevstart : Math.max(this.props.start - this.props.count, 0),
    };
  }

  componentWillReceiveProps( newProps ) {
    this.setState( {
      nextstart : newProps.start + newProps.count,
      prevstart : Math.max(newProps.start - newProps.count, 0),
    });
  }

  handlePrevPage(e, ns: number ) {
    e.preventDefault();
    if ( ns >= 0)
      this.props.onLoadPage(ns);
  }

  handleNextPage(e, ns: number ) {
    const { total, itemsInPage, count } = this.props;
    e.preventDefault();
    if ( total < 0 && itemsInPage && itemsInPage >= count ) {
      this.props.onLoadPage(ns);
    }
    else if ( ns < total) {
      this.props.onLoadPage(ns);
    }
  }

  render() {
    const { start, count, total, classExtra, itemsInPage } = this.props;

    const nextstart = start + count;
    const prevstart = Math.max(start - count, 0);
    const currentPage = count > 0 ? Math.ceil( nextstart / count) : 1;
    const totPages = total > 0 ? Math.ceil(total / count) : Number.MAX_VALUE;

    const nextPageClass = classNames({
      'active': ( currentPage === totPages || itemsInPage < count ) ? false : true,
      'fa fa-chevron-circle-right': true
    });
    const prevPageClass = classNames({
      'active': currentPage === 1 ? false : true,
      'fa fa-chevron-circle-left': true
    });

    let pageInfo = total > 0 ? <mark>{currentPage}/{totPages}</mark> : <mark>{currentPage}</mark>;
    return ( <span className={`page-controls ${classExtra ? classExtra : ''}`}>
               <a href="#" onClick={ (e) => this.handlePrevPage(e,prevstart)}><i className={prevPageClass}></i></a>
                 {pageInfo}
               <a href="#" onClick={ (e) => this.handleNextPage(e, nextstart)}><i className={nextPageClass}></i></a>
             </span>);
  }
} // End class Pager

// ================================= <TextInput> =================================

export interface Suggestion {
  name: string;
  key: string;
}

/**
 * @internal
 */
export interface InputFeedback {
  status: 'ok' | 'error' | 'warning';
  message?: string;
}

/**
 * @internal
 */
interface TextInputProps {
  id: string;
  value: string;
  label?: string;
  placeholder?: string;
  password?:boolean;
  allowsave?:boolean;
  disabled?:boolean;
  ref?: any;
  addon?: string; // A fontawesome style for an additional button on the right.
  showFeedback?: boolean;
  suggestions?: Suggestion[];
  suggestEmpty?: boolean;
  suggestIcon?: string | JSX.Element;
  onChange?: ( value: string ) => InputFeedback ;
  onEnter?: ( value: string ) => void;
  onClickAddon? : (value: string ) => void;
  name?: string;
}

/**
 * @internal
 */
interface TextInputState {
  value?: string;
  status?: string;  // ok | error | warning
  warningMessage?: string;
  suggestions?: any[];
}

/**
 * @internal
 */
export class TextInput extends React.Component<TextInputProps,TextInputState> {

  _input: Element;

  constructor( p: TextInputProps) {
    super(p);
    this.state = {
      value: p.value || '',
      suggestions: p.suggestions
    };
  }

  feedback( f: InputFeedback ) {
    if ( this.props.showFeedback === false || !f )
      return;
    var self = this;
    const warningChanged = this.state.warningMessage !== f.message;
    const statusChanged = this.state.status !== f.status;
    this.setState({
      status: f.status,
      warningMessage: f.message || this.state.warningMessage
    });
    _.defer( () => {
      if ( warningChanged || statusChanged) {
        if ( this.state.warningMessage && this.state.status !== 'ok' ) {
          // Update tooltip content without closing and reopening the tooltip.
          // http://stackoverflow.com/questions/9501921/change-twitter-bootstrap-tooltip-content-on-click/20713610
          $(self._input).attr('title', this.state.warningMessage )
                        .tooltip('fixTitle')
                        .parent().find('.tooltip .tooltip-inner').text(this.state.warningMessage)                      ;
          if ( statusChanged )
            $(self._input).tooltip('show');
        }
        else {
        }
      }
    });
  }

  validate = ( newValue: string ) => {
    var self = this;
    if ( this.props.onChange ) {
      this.feedback( this.props.onChange( newValue ) );
    }
    else
      this.setState({ value: newValue });
  }

  componentDidMount() {
    // React.findDOMNode(this.refs[this.props.id]) as React..focus();
  }

  componentWillReceiveProps( p: TextInputProps ) {
    this.setState({ value: p.value });
    // If the value is reset to nothing then reset the warning status
    if ( !p.value ) {
      this.setState({ status: '', warningMessage: '' });
    }
  }

  handleInputChange( e, data?: { newValue: string, method: string } ) {
    if ( data ) {
      this.validate( data.newValue );
    }
    else {
      this.validate( e.target.value );
    }
  }

  handleKeyup( e ) {
    if ( e.which === 13 && this.props.onEnter ) {
      this.props.onEnter(this.state.value);
    }
  }

  handleAddonclick( e ) {
    e.stopPropagation();
    e.preventDefault();
    if ( this.props.onClickAddon )
      this.props.onClickAddon(this.state.value);
  }

  getSuggestionForInput = ( inputValue ) => {
    if ( inputValue === '' && this.props.suggestEmpty ) {
      return this.props.suggestions;
    }
    else {
      return _.filter( this.props.suggestions, (s:Suggestion) => s.name.indexOf(inputValue) >= 0 );
    }
  }

  getSuggestionValue = (suggestion) => {
    return suggestion ? suggestion.name : '';
  };

  /**
   * Render the suggestion received as parameter.
   * @memberOf TextInput
   */
  renderSuggestion = ( suggestion: Suggestion ) => {
    const { value, suggestIcon } = this.props;
    const segments = suggestion.name.replace( value,`|${value}|`).split('|');
    let icon = <span/>;
    if ( suggestIcon ) {
      if (typeof suggestIcon === 'string') {
        let iconClass = `fa ${suggestIcon}`;
        icon = <i className={iconClass}></i>;
      } else {
        icon = suggestIcon;
      }
    }
    if ( segments.length > 2 ) {
      return <span className="auto-suggestion">
        {icon} {segments[0]}<strong>{segments[1]}</strong>{segments[2]}
      </span>;
    }
    else {
      return <span className="auto-suggestion">{icon} { suggestion.name || suggestion }</span>;
    }

  }

  onSuggestionsFetchRequested = (input) => {
    const sugg = this.getSuggestionForInput(input.value);
    this.setState({ suggestions: sugg });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  shouldRenderSuggestions = (value) => {
    if ( this.props.suggestEmpty )
      return true;
    else {
      return value.length > 1;
    }
  }

  renderInput() {
    const { password, value, id, disabled, allowsave, suggestions, placeholder } = this.props;
    const itype = password ? 'password' : 'text';

    let name = this.props.name ? this.props.name : id;

    if ( password || !this.props.suggestions || this.props.suggestions.length === 0 ) {
      return <input type={itype}
                    id={id}
                    disabled={disabled}
                    className="form-control"
                    onKeyUp={this.handleKeyup.bind(this)}
                    onChange={this.handleInputChange.bind(this)}
                    autoComplete={ (allowsave || allowsave === undefined ) ? 'on' : 'off' }
                    value={value == null ? '' : value}
                    placeholder={ placeholder }
                    data-toggle="tooltip"
                    data-placement="bottom"
                    name={name} />;
    }
    else {
      const inputProps = {
        'id': id,
        'placeholder': placeholder,
        'value': value,
        'data-toggle': 'tooltip',
        'data-placement': 'bottom',
        'onChange': this.handleInputChange.bind(this),
        'className': 'form-control'
      };
      return <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    inputProps={inputProps} />;
    }
  }

  render() {
    const { addon, password, label, showFeedback, value, id, onClickAddon } = this.props;
    var vl = value ? this.state.value.length : 0;
    const fieldClass = classNames({
      'form-group' : true,
      'has-feedback': showFeedback,
      'has-warning': this.state.status === 'warning' && vl > 0,
      'has-error': this.state.status === 'error' && vl > 0,
      'has-success': this.state.status === 'ok'
    });
    const inputIconClass = classNames({
      'fa': true,
      'form-control-feedback': false,
      'fa-warning': this.state.status === 'warning' && vl > 0,
      'fa-exclamation-circle': this.state.status === 'error' && vl > 0,
      'fa-check': this.state.status === 'ok',
    });
    let feedback = <span/>;
    let addonContent = <span/>;
    const itype = password ? 'password' : 'text';
    const labelContent =  label ? <label>{this.props.label}</label> : <span/>;

    if ( (showFeedback === undefined || showFeedback ) && value && this.state.value.length > 0 && this.state.status ) {
      const inputFeedbackClass = classNames({
        'input-group-addon': true
      });
      feedback = <div className={inputFeedbackClass} id={`${id}-feedback`}>
        <i className={inputIconClass} title={this.state.warningMessage}></i>
      </div>;
    }
    if ( addon ) {
      if ( onClickAddon ) {
        addonContent = <div className="input-group-addon"
                            style={ { cursor: 'pointer' } }
                            id={`${id}-addon`}
                            onClick={this.handleAddonclick.bind(this)}>
          <i className={`fa ${addon}`} ></i>
        </div>;
      }
      else {
        addonContent = <div className="input-group-addon"
                            style={ { cursor: 'pointer' } }
                            id={`${id}-addon`} >
          <i className={`fa ${addon}`} ></i>
        </div>;
      }
    }
    return (<div className={fieldClass}>
              {labelContent}
              <div className="input-group" id={`${id}-group`}
                   style={ {width: '100%' }}
                   ref={ (cmp) => this._input = ReactDOM.findDOMNode(cmp) as Element}>
                {this.renderInput()}
                {feedback}
                {addonContent}
              </div>
            </div>);
  }
}

// ================================= <UploadInput> =================================

interface UploadInputProps {
  dict: site.LocaleDictionary;
  id: string;
  value: string;
  user: site.UserInfo;
  label?: string;
  placeholder?: string;
  ref?: any;
  showFeedback?: boolean;
  suggestions?: Suggestion[];
  suggestEmpty?: boolean;
  suggestIcon?: string;
  endPoints: {
    target: string;
    finalize: string;
  };
  onProgress?: ( perc: number, complete: boolean ) => InputFeedback ;
  onComplete?: ( value: string ) => InputFeedback ;
  onChange?: ( value: string ) => InputFeedback ;
  onEnter?: ( value: string ) => void;
}

interface UploadInputState {
  filename?: string;
  uploading?: 'none' | 'inprogress' | 'complete' | 'error' ;
  progress?: number;
  message?: string;
}

/**
 *
 * @internal
 * @export
 * @class UploadInput
 * @extends {React.Component<UploadInputProps, UploadInputState>}
 */
export class UploadInput extends React.Component<UploadInputProps,UploadInputState> {

  _textinput : TextInput;

  constructor( p: UploadInputProps) {
    super(p);
    this.state = {
      filename: '',
      uploading: 'none',
      progress: 0.0
    };
  }

  componentWillReceiveProps( newProps: UploadInputProps ) {
    if ( newProps.value && newProps.value.length > 0 && this.state.uploading !== 'inprogress' ) {
      this.state = {
        filename: '',
        uploading: 'none',
        progress: 0.0
      };
    }
  }

  // componentWillUnmount() {
  //   this._textinput = null;
  //   tvgUploader.resetAll();
  // }

  componentDidMount() {
    const { id, user, dict, endPoints } = this.props;
    // const self = this;
    // Initialize the uploader module to work on the input
    tvgUploader.resetAll();
    tvgUploader.init({ dropElementId: id,
                       browseElementId: `${id}-addon`,
                       name: user.username,
                       userId: user.id,
                       chunksize: 2 * 1024 * 1024,
                       dict: dict,
                       endPoints : endPoints,
                       trackGlobalProgress: false,
                       acceptMultipleFiles: false,
                       renderFileProgress: false // we will receive 'fileprogress' events
                     });
    tvgUploader.onStart( ( fname: string ) => {
      this.setState({ uploading: 'inprogress', progress: 0, filename: fname });
      this._textinput.feedback( this.props.onProgress( 0.0, false ) );
    });
    tvgUploader.onFail( ( err: Error ) => {
      this.setState({ uploading: 'error', progress: 0, message: err.message} );
      if ( err['file'] ) {
        this.setState( { filename : err['file'].name });
      }
      this._textinput.feedback( {status: 'error', message: err.message } );
    });
    tvgUploader.onFileProgress( ( file: { progress: number; name: string } ) => {
      this.setState({
        uploading: 'inprogress',
        progress: file.progress,
        filename: file.name
      });
      this._textinput.feedback( this.props.onProgress( file.progress, false ) );
    });
    tvgUploader.onComplete( ( count: number ) => {
      if ( count === 0 ) {
         _.delay( () => {
          this.setState({
            uploading: 'none',
            progress: 0.0
          });
           tvgUploader.reset();
          }, 2000 );
        return;
      }
      this.setState({
        uploading: 'inprogress',
        progress: 100.0
      });
      this._textinput.feedback( this.props.onProgress( 100.0, false ) );
      _.delay( () => {
        this.setState({ uploading: 'complete', progress: 100.0 });
        this.props.onComplete( this.state.filename );
        this._textinput.feedback( this.props.onProgress( 100.0, true ) );
        tvgUploader.reset();
      }, 1000 );
    });
  }

  // onChange={this.handleChangeInput.bind(this)}
  // handleChangeInput() {
  //   // this.setState({
  //   //   uploading: 'none',
  //   //   progress: 0.0
  //   // });
  // }

  render() {
    const { uploading, progress, filename } = this.state;
    const progressContainerClass = classNames({
      'progress': true,
      'input-progress-bar': true,
      'hidden': uploading === 'none',
    });
    const progressClass = classNames({
      'progress-bar': true,
      'progress-bar-info': uploading === 'inprogress',
      'progress-bar-danger': uploading === 'error',
      'progress-bar-success': uploading === 'complete'
    });
    return <div>
      <TextInput {...this.props}
                 value={ uploading !== 'none' ? filename : this.props.value }
                 addon="fa-ellipsis-h" ref={ (c) => { if ( c !== null ) {this._textinput = c ; }} } />
      <div className={progressContainerClass}>
        <div className={progressClass}
             role="progressbar"
             aria-valuenow={progress}
             aria-valuemin={0}
             aria-valuemax={100}
             style={ {width: `${progress}%` }} >
          <span className="sr-only">{progress}%</span>
        </div>
      </div>
    </div>;
  }
}

// ================================= <CheckInput> =================================


/**
 * @internal
 */
interface CheckInputProps {
  id: string;
  value: boolean;
  label: string;
  labelOnTrue: string;
  labelOnFalse: string;
  width?: number;
  enabled?: boolean;
  onChange?: (nv: boolean ) => void;
}

/**
 * @internal
 */
interface CheckInputState {
  value: boolean;
}

/**
 * @internal
 */
export class CheckInput extends React.Component<CheckInputProps,CheckInputState> {
  constructor( p: CheckInputProps) {
    super(p);
    this.state = {
      value: p.value
    };
  }

  componentWillReceiveProps( p: CheckInputProps ) {
    this.setState({value: p.value });
  }

  handleChange( newValue: boolean ) {
    this.setState({value: newValue });
    if ( this.props.onChange )
      this.props.onChange(newValue);
  }

  render() {
    const { enabled, labelOnTrue, labelOnFalse } = this.props;
    var fieldClass = classNames({
      'form-group' : true,
    });
    return  (<div className={fieldClass}>
              <label>{this.props.label}</label>
              <div>
                <Switch id={this.props.id} on={this.state.value}
                        onLabel={labelOnTrue}
                        offLabel={labelOnFalse}
                        width={this.props.width}
                        enabled={ enabled === undefined ? true : enabled }
                        onChange={this.handleChange.bind(this)}/>
               </div>
            </div>);
  }
}


// ================================= <RadioInput> =================================

/**
 * @internal
 */
export interface CheckInputValues {
  [ options: string ] : boolean;
}

/**
 * @internal
 */
interface ChecksInputProps {
  values: CheckInputValues;
  label: string;
  type?: string; // 'radio' or 'checkbox' (checkbox is the default)
  maxHeight?: number;
  onChange: ( option: string, newValue: boolean ) => void ;
}

/**
 * @internal
 */
export class ChecksInput extends React.Component<ChecksInputProps,{}> {
  constructor( p: ChecksInputProps) {
    super(p);
    this.state = {};
  }

  handleCheckChange( option: string, e: React.FormEvent<any> ) {
    let { onChange, values } = this.props;
    e.stopPropagation();
    if ( onChange )
      onChange( option, !values[option] );
  }

  renderChecks() {
    let { values, type } = this.props;
    const checkType = type || 'checkbox';
    return _.values(
      _.mapValues( values, ( checked: boolean, opt: string  ) => {
        let checkClass = classNames({
          'input-check': true,
          'checked': checked
        });
        return <span className={checkClass} onClick={(e) => this.handleCheckChange(opt,e)} key={opt} title={opt}>
                  <input type={checkType} checked={!!checked} value={opt} readOnly={true} />
                  {opt}
               </span>;
    } ));
  }

  render() {
    const { maxHeight , label } = this.props;
    var fieldClass = classNames({
      'form-group' : true,
    });
    let maxHeightStyle = {};
    if ( maxHeight ) {
      maxHeightStyle = { maxHeight: `${maxHeight}px`, overflowY: 'auto' };
    }
    return <div className={fieldClass}>
             <label>{label}</label>
             <div style={maxHeightStyle}>
               {this.renderChecks()}
             </div>
           </div>;
  }
}

// =========================================== PopupHeader ======================================


interface PopupFooterProps {
  dict?: site.LocaleDictionary;
  okEnabled: boolean;      // default: true
  cancelEnabled?: boolean;  // default: true
  warningMessage?: string;  // default: ''
  onOk?: ( e:any) => void;
  onCancel?: ( e: any) => void;
}

/**
 *
 * @internal
 * @class PopupFooter
 * @extends {React.Component<PopupFooterProps, {}>}
 */
class PopupFooter extends React.Component< PopupFooterProps, {} > {

  constructor(props: PopupFooterProps) {
    super(props);
  }

  render() {
    const { cancelEnabled, okEnabled } = this.props;
    const okClass = classNames({
      'btn' : true,
      'btn-primary': true,
      'disabled' : !okEnabled
    });
    let cancel = <span/>;
    if ( cancelEnabled == undefined || cancelEnabled )
      cancel = <button type="button" className="btn btn-default" onClick={this.props.onCancel}>{this.props.dict['cancel'] || 'Cancel' } </button>;
    return (
      <div className="modal-footer">
        <span className="modal-message pull-left">{this.props.warningMessage}</span>
        {cancel}
        <button type="button" className={okClass} disabled={!okEnabled} onClick={this.props.onOk}>{this.props.dict['ok'] || 'Ok'} </button>
      </div>
    );
  }
}


// =========================================== PopupHeader ======================================

interface PopupHeaderProps {
  icon: string | JSX.Element;
  children?: any; //React.Component<any,any>[];
  onCancel?: (e:any) => void;
  rightToLeft?: boolean;
}


/**
 *
 * @internal
 * @class PopupHeader
 * @extends {React.Component<PopupHeaderProps, {}>}
 */
class PopupHeader extends React.Component< PopupHeaderProps, {} > {

  _styles: StyleMap = {
    'closeBt' : {
      'padding':'2px',
      'marginTop': '-6px',
      'fontSize': '15px',
      'border': 'none'
    }
  };

  constructor(props: PopupHeaderProps) {
    super(props);
  }

  render() {
    let icon : JSX.Element ;
    if ( this.props.icon ) {
      if (typeof this.props.icon === 'string') {
        var iconClass = classNames( 'fa ', this.props.icon);
        icon = (<i className={iconClass}></i>);
      } else {
        icon = this.props.icon;
      }
    }
    // <span aria-hidden="true">&times;</span>
    return (
      <div className="modal-header">
        <button type="button" className="close" onClick={this.props.onCancel} aria-label="Close" style={this._styles['closeBt']} >
          <i className="fa fa-times"></i>
        </button>
        <h4 className="modal-title" style={ this.props.rightToLeft ? {direction:'rtl', width:'fit-content'}:{}}>{icon} {this.props.children}</h4>
      </div>
    );
  }

}


// =========================================== Popup ======================================

/**
 *
 * @internal
 */
export interface PopupProps {
  id: string;
  key?: string;
  dict: site.LocaleDictionary;
  icon?: string | JSX.Element;
  okEnabled: boolean;      // default: true
  cancelEnabled?: boolean;  // default: true
  small?: boolean;          // default: false
  warningMessage?: string;  // default: ''
  message?: string;         // default: ''
  header?: string;          // default: ''
  footer?: string;          // default: ''
  children?: any; //(React.Component<any,any> | JSX.Element )[];
  show?: boolean;           // default: false;
  onOk?: ( ) => void;
  onCancel?: ( ) => void;
  ref?: string | ((component: Popup) => any);
  rightToLeft?: boolean;
}

/**
 * @internal
 */
interface PopupState {
  show?: boolean;
}

/**
 * Render a popup window centerd with an OK and Cancel button
 * @internal
 */
export class Popup extends React.Component< PopupProps, PopupState > {

  nodeJQ: JQuery;

  constructor(props: PopupProps) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  componentDidMount() {
    this.nodeJQ = $(`#${this.props.id}`);
    if ( this.state.show )
      this.nodeJQ.modal('show');
    else
      this.nodeJQ.modal('hide');
  }

  componentWillUnmount() {
    this.nodeJQ.modal('hide');
  }

  handleClick(e) {
    e.stopPropagation();
  }

  handleOk(e) {
    if ( this.props.onOk )
      this.props.onOk();
  }

  handleCancel( e ) {
    e.preventDefault();
    if ( this.props.onCancel )
      this.props.onCancel();
  }

  componentWillReceiveProps( newProps ) {
    this.nodeJQ = $(`#${newProps.id}`);
    this.setState({
      show: newProps.show
    });
    if ( newProps.show )
      this.nodeJQ.modal('show');
    else
      this.nodeJQ.modal('hide');
  }

  render() {
    const { cancelEnabled , dict, icon, warningMessage, header, id, okEnabled, rightToLeft } = this.props;
    var self = this;
    var cName = classNames({
      'modal-dialog' : true,
      'modal-sm' : this.props.small
    });
    const showCancelBt = cancelEnabled !== undefined ? cancelEnabled : true;
    return (
      <div onClick={self.handleClick.bind(self)} id={id} className="modal" role="dialog"
           aria-hidden="true"
           data-keyboard="false"
           data-backdrop="static">
        <div className={cName}>
          <div className="modal-content">
            <PopupHeader 
              icon={icon} 
              onCancel={self.handleCancel.bind(self)}
              rightToLeft={ rightToLeft }>
              {header || '' }
            </PopupHeader>
            <div className="modal-body">
              {this.props.children}
            </div>
            <PopupFooter dict={dict}
                         onOk={self.handleOk.bind(self)}
                         onCancel={self.handleCancel.bind(self)}
                         okEnabled={okEnabled}
                         cancelEnabled={showCancelBt}
                         warningMessage={warningMessage}>
              {self.props.footer || ''}
            </PopupFooter>
          </div>
        </div>
      </div>
    );
  }
}

// ====================================== UploadPopup ============================================

/**
 * @internal
 */
interface UploadPopupProps extends PopupProps {
  dict: site.LocaleDictionary;
  endPoints: {
    target: string;
    finalize: string;
  };
  onStart?: () => void;
  onComplete?: ( fn: string ) => void;
  onStartRestore?: ( fn: string ) => void;
  extensions?: string[];
  rightToLeft?: boolean;
}

/**
 * @internal
 */
interface UploadPopupState extends PopupState {
  uploading?: boolean;
  complete?: boolean;
  start?: boolean;
  filename?: string;
}

/**
 * Render a popup dialog customixed to perform upload operations.
 * The dialog presents a drop target and a browse button and can be further
 * customized adding children elements to it.
 *
 * @internal
 */
export class UploadPopup extends React.Component< UploadPopupProps, UploadPopupState > {
  constructor(props: UploadPopupProps) {
    super(props);
    this.state = {
      start: false,
      complete: false,
      uploading: false
    };
  }

  componentWillReceiveProps( newProps: UploadPopupProps ) {
    if ( this.props.show != newProps.show ) {
      tvgUploader.reset();
      this.setState({
        start: false,
        complete: false,
        uploading: false
      });
    }
  }

  componentDidMount() {
    var self = this;
    tvgUploader.reset();
    tvgUploader.init({ dropElementId: 'uploadTarget',
                       browseElementId: 'uploadBrowse',
                       name: 'user',
                       userId: 'userid',
                       dict: this.props.dict,
                       endPoints : this.props.endPoints,
                       acceptExtensions: this.props.extensions,
                       trackGlobalProgress: false,
                       acceptMultipleFiles: false,
                       renderFileProgress: true
                     });
    // Capture the Complete Upload event
    tvgUploader.onFileComplete( ( res: tvgUploader.FinalizeResult ) => {
      self.setState({ complete: true, filename: res.path,  uploading: false });
      if ( self.props.onComplete )
         self.props.onComplete( res.name );
    });
    // Capture the Start Upload event
    tvgUploader.onStart( (n: number) => {
      self.setState({ start: true, complete: false, uploading: true });
      if ( self.props.onStart )
         self.props.onStart();

    });
    tvgUploader.onPause( (n: number) => {
      if ( confirm('Are you sure you want to abort the upload process?') ) {
        self.setState({show:false});
        if ( self.props.onCancel )
          self.props.onCancel();
      }
      else {
        if ( !this.state.complete )
          tvgUploader.startUpload();
      }
    });

  }

  handleCancel() {
    if ( this.state.uploading || this.state.complete )
      tvgUploader.pauseUpload();
    else if ( this.props.onCancel )
      this.props.onCancel();
  }

  handleOk() {
     if ( this.props.onStartRestore )
      this.props.onStartRestore( this.state.filename );
     else if ( this.props.onOk )
      this.props.onOk();

  }

  render() {
    var browseClass = classNames( {
      'btn': true,
      'btn-default': true,
      'disabled': this.state.start
    } );
    const rtl = this.props.rightToLeft;

    return (<Popup id={this.props.id}
                    dict={this.props.dict}
                    show={this.props.show}
                    header={this.props.header}
                    icon={this.props.icon}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    okEnabled={this.state.complete}
                    rightToLeft={ rtl }>
                <p className="upload-info" style={ rtl ? {direction: 'rtl'}:{}}>
                  <button className={browseClass} id="uploadBrowse" type="submit">{this.props.dict['browse']}</button>
                  <span>{this.props.dict['uplinstruction']}</span>
                </p>
                <div className="upload-target-frame" >
                  <i id="bkgico" className="fa fa-cloud-upload"></i>
                  <div id="uploadTarget" className="upload-target"></div>
                </div>
                {this.props.children}
            </Popup>);
  }
}


// ========================== <SavingMsg> =====================================================

interface MessageProps {
  message?: string;
  show: boolean;
  icon?: string;
  children?: any; //any[];
}

/**
 * @internal
 */
export class WarningMsg extends React.Component< MessageProps, {} >{

  constructor(props: MessageProps) {
    super(props);
  }

  render() {
    var savingClass = classNames({
      'alert': true,
      'alert-warning': true,
      'hidden': !this.props.show
    });
    const content = this.props.message || this.props.children;
    var iconClass = classNames({
      'fa': true,
      'fa-exclamation-circle': !this.props.icon },  this.props.icon);
    return (<div className={savingClass} role="alert">
              <i className={iconClass}></i> <strong> {content}</strong>
              <div>
                {this.props.children}
              </div>
            </div>);
  }
}

export class WarningMsg2 extends React.Component< MessageProps, {} >{

  constructor(props: MessageProps) {
    super(props);
  }

  render() {
    const savingClass = classNames({
      'alert': true,
      'alert-warning': true,
      'hidden': !this.props.show
    });
    const iconClass = classNames({
      'fa': true,
      'fa-exclamation-circle': !this.props.icon },  this.props.icon);
    const content = !!this.props.message ? [<i className={iconClass}></i>, <strong> {this.props.message}</strong>] : [];
    const children = !!this.props.children ? <div>{this.props.children}</div> : null;
    return (<div className={savingClass} role="alert">
              {content}
              {children}
            </div>);
  }
}

/**
 * @internal
 */
export class InProgressMsg extends React.Component< MessageProps, {} >{

  constructor(props: MessageProps) {
    super(props);
  }

  render() {
    var savingClass = classNames({
      'alert': true,
      'alert-warning': true,
      'hidden': !this.props.show
    });

    return (<div className={savingClass} role="alert">
              <i className="fa fa-cog fa-spin"></i> {this.props.message}
              <p>
                {this.props.children}
              </p>
            </div>);
  }
}

/**
 * @internal
 */
export class SuccessMsg extends React.Component< MessageProps, {} >{

  constructor(props: MessageProps) {
    super(props);
  }

  render() {
    var savingClass = classNames({
      'alert': true,
      'alert-success': true,
      'hidden': !this.props.show
    });

    return (<div className={savingClass} role="alert">
              <i className="fa fa-thumbs-o-up"></i> {this.props.message}
              <p>
                {this.props.children}
              </p>
            </div>);
  }
}

/**
 * @internal
 */
export class FailedMsg extends React.Component< MessageProps, {} >{

  constructor(props: MessageProps) {
    super(props);
  }

  render() {
    var savingClass = classNames({
      'alert': true,
      'alert-danger': true,
      'hidden': !this.props.show
    });

    return (<div className={savingClass} role="alert">
              <i className="fa fa-exclamation-circle"></i> {this.props.message}
              <p>
                {this.props.children}
              </p>
            </div>);
  }
}


// ========================== <SavingMsg> =====================================================

/**
 * @internal
 */
interface SavingMsgProps {
  action: string;
  dict: site.LocaleDictionary;
  messageid: string;
}

/**
 * @internal
 */
export class SavingMsg extends React.Component<SavingMsgProps,{}> {
  constructor( p:SavingMsgProps ) {
    super(p);
  }
  render() {
    var savingClass = classNames({
      'alert': true,
      'alert-info': true,
      'hidden': this.props.action !== 'saving'
    });

    return (<div className={savingClass} role="alert">
              <i className="fa fa-cog fa-spin"></i> {this.props.dict[this.props.messageid]}
            </div>);
  }
}

// ========================== <ActionResult> =====================================================

interface ActionResultProps {
  success?: boolean;
  code?: number;
  title?: string;
  msg?: string;
}

/**
 * @internal
 */
export class ActionResult extends React.Component<ActionResultProps,{}> {
  constructor(p) {
    super(p);
  }

  render() {
    var resultClass = classNames({
      'alert': true,
      'alert-success': this.props.success ? true : false,
      'alert-danger': !this.props.success ? true : false,
    });
    var resultTitle = (<p><i className="fa fa-check"></i> <strong>Success</strong></p>) ;
    if ( !this.props.success )
      resultTitle = (<p><i className="fa fa-warning"></i> <strong>{this.props.code} - {this.props.title}</strong></p>);

    return ( <div className={resultClass} role="alert">
                {resultTitle}
                <p>{this.props.msg || '' }</p>
             </div>);

  }
}

// ========================== <TSlider> =====================================================

interface SliderProps {
  id: string;
  title: string;
  min: number;
  max: number;
  value?: number;
  compact: boolean;
  enabled: boolean;
  valueStyle? : 'value' | 'valuerange' | 'percentage';
  onChange?: (v: number) => void;
  step?: number;
}

interface SliderState {
  value?: number;
}

/**
 * @internal
 */
export class TSlider extends React.Component< SliderProps, SliderState > {
  constructor( p: SliderProps ) {
    super(p);
    this.state = {
      value: p.value || p.min
    };
  }

  componentDidMount() {
    var self = this;
    var bslider = $('#' + this.props.id).slider({
      min: this.props.min,
      max: this.props.max,
      value: this.props.value || this.props.min,
      tooltip: 'hide',
      step: this.props.step || 1
    });
    bslider.on('change', ( c: any ) => {
      self.handleChange(c.value.newValue);
    });
  }

  componentWillUnmount() {
    $('#' + this.props.id).slider('destroy');
  }

  componentWillReceiveProps(p:SliderProps ) {
    var nValue = p.value || p.min;
    $('#' + this.props.id).slider({
          min: p.min,
          max: p.max,
          step: p.step || this.props.step
        });
    if ( this.state.value !== nValue) {
      $('#' + this.props.id).slider('setValue', nValue );
      this.setState({ value: nValue });
    }
  }

  handleChange( val: number ) {
    this.setState({ value: val });
    if ( this.props.onChange )
      this.props.onChange(val);
  }

  render() {
    var formClass = classNames({
      'form-group slider-group' : true,
      'enabled': this.props.enabled,
      'compact': this.props.compact
    });
    var labelClass = classNames({
      'field slider-label': true,
      'compact': this.props.compact
    });
    var sliderClass = classNames({
      'slider-control': true,
      'compact': this.props.compact
    });
    let value = <span>{this.state.value} / {this.props.max}</span> ;
    switch( this.props.valueStyle ) {
      case 'value':
        value = <span>{this.state.value}</span> ;
        break;
      case 'percentage':
        value = <span>{((this.state.value / this.props.max) * 100.0).toFixed(0)}%</span> ;
        break;
      default:
        value = <span>{this.state.value} / {this.props.max}</span>;
        break;
    }

    return (<div className={formClass}>
              <label className={labelClass} >{this.props.title}</label >
              <div className={sliderClass}>
                <input type="range" className="form-control"  id={this.props.id}
                      onChange={this.handleChange.bind(this)}
                      onMouseUp={this.handleChange.bind(this)} />
              </div>
              <span className="label label-primary">{value}</span>
           </div>);
  }
}


// ========================== < SliderExponentialProps > =====================================================
/*  Exponential Slider
          |           .
          |          .
   maxval |---------.
          |       . |
          |     .   |
   minval |--.      |
          |. |      |
          -------------------- slider scale
            min   max

  value is the actual value to be set in the db

  */

export interface SliderExponentialProps {
  maxval: number;
  minval: number;
  max: number;
  min: number;
  value: number;
  step?: number;
  onChange: ( v: number ) => void;
  enabled: boolean;
  id: string;
  title: string;
  compact: boolean;
}
export interface SliderExponentialState {
  value: number;
}

export class SliderExponential extends React.Component < SliderExponentialProps, SliderExponentialState> {
  constructor( p ) {
    super(p);
    this.state = {
      value: p.value,
    };
  }
  componentDidMount() {
    const { id, max, min, step } = this.props;
    const { value } = this.state;
    let customstep = step || ( max - min ) / 100;
    let slidervalue = this.valToSlider( value );
    let slider = $( '#' + id ).slider({
      min: min,
      max: max,
      value: slidervalue || min,
      tooltip: 'hide',
      step: customstep
    });
    slider.on( 'change', ( c ) => {
      this.handleOnChange( c );
    });
  }
  componentWillUnmount() {
    $( '#' + this.props.id ).slider( 'destroy' );
  }
  componentWillReceiveProps( p ){
    if( p.value !== this.state.value ) {
      let slidervalue = this.valToSlider( p.value );
      $('#' + this.props.id ).slider('setValue', slidervalue );
      this.setState({ value: p.value });
    }
  }
  sliderToVal( s ) {
    const { minval, maxval, min, max } = this.props;
    let q = minval * Math.pow( maxval / minval, ( s - min ) / ( max - min ));
    let rounded = Math.round( q * 100) / 100;
    return rounded;
  }
  valToSlider( v ) {
    const { minval, maxval, min, max } = this.props;
    let q = min + ( max - min ) * Math.log( v / minval) / Math.log( maxval / minval );
    let rounded = Math.round( q * 10) / 10;
    return rounded;
  }
  handleOnChange( a ) {
    const { onChange } = this.props;
    let actualvalue = this.sliderToVal( a.target.value );
    onChange( actualvalue );
    this.setState({ value: actualvalue });
  }
  render(){
    const { min, max, step, id, compact, enabled, title } = this.props;
    const { value } = this.state;
    let slidervalue = this.valToSlider( value );
    let customstep = step || ( max - min ) / 100;
    const formClass = classNames({
      'form-group slider-group' : true,
      'enabled': enabled,
      'compact': compact
    });
    const labelClass = classNames({
      'field slider-label': true,
      'compact': compact
    });
    const sliderClass = classNames({
      'slider-control': true,
      'compact': compact
    });
    return (
      <div className={ formClass } >
        <label className={ labelClass } > { title } </label>
        <div className={ sliderClass } >
          <input className="form-control" type="range" id={ id }
                min={ min } max={ max } value={ slidervalue } step={ customstep }
                onChange={ this.handleOnChange.bind(this) } />
        </div>
        <span className="label label-primary">{ value }</span>
      </div>
    );
  }
}

// ========================== < SliderLogarithmicProps > =====================================================
/*  Logarithmic Slider
  Greg called this a logarithmic slider but it is more like an exponential slider....
*/

 export interface SliderLogarithmicProps {
  // maxval: number;
  // minval: number;
  // max: number;
  // min: number;
  value: number;
  step?: number;
  onChange: ( v: number ) => void;
  enabled: boolean;
  id: string;
  title: string;
  compact: boolean;
}
export interface SliderLogarithmicState {
  value: number;
}

export class SliderLogarithmic extends React.Component < SliderLogarithmicProps, SliderLogarithmicState> {
  constructor( p ) {
    super(p);
    this.state = {
      value: p.value,
    };
  }
  componentDidMount() {
    const { id, step } = this.props;
    const { value } = this.state;
    let customstep = 0.001;
    let slidervalue = this.valToSlider( value );
    let slider = $( '#' + id ).slider({
      min: -50, //min,
      max: 50, //max,
      value: slidervalue || 1, //min,
      tooltip: 'hide',
      step: customstep
    });
    slider.on( 'change', ( c ) => {
      this.handleOnChange( c );
    });
  }
  componentWillUnmount() {
    $( '#' + this.props.id ).slider( 'destroy' );
  }
  componentWillReceiveProps( p ){
    if( p.value !== this.state.value ) {
      let slidervalue = this.valToSlider( p.value );
      $('#' + this.props.id ).slider('setValue', slidervalue );
      this.setState({ value: p.value });
    }
  }
  sliderToVal( s ) {
    let exponent = Number(s) / 20;
    let result = Math.pow( 10, exponent);
    return Math.round( result * 1000 ) / 1000;  
  }
  valToSlider( v ) {
    let result = 20 * Math.log10( Number(v) );
    return Math.round( result * 1000 ) / 1000;
  }
  handleOnChange( a ) {
    const { onChange } = this.props;
    let actualvalue = this.sliderToVal( a.target.value );
    onChange( actualvalue );
    this.setState({ value: actualvalue });
  }
  render(){
    const { step, id, compact, enabled, title } = this.props;
    const { value } = this.state;
    let slidervalue = this.valToSlider( value );
    let customstep = 0.001; //step || ( max - min ) / 100;
    const formClass = classNames({
      'form-group slider-group' : true,
      'enabled': enabled,
      'compact': compact
    });
    const labelClass = classNames({
      'field slider-label': true,
      'compact': compact
    });
    const sliderClass = classNames({
      'slider-control': true,
      'compact': compact
    });
    return (
      <div className={ formClass } >
        <label className={ labelClass } > { title } </label>
        <div className={ sliderClass } >
          <input className="form-control" type="range" id={ id }
                min={ -50 } max={ 50 } value={ slidervalue } step={ customstep }
                onChange={ this.handleOnChange.bind(this) } />
        </div>
        <span className="label label-primary">{ value }</span>
      </div>
    );
  }
}


// ========================== <TRange> =====================================================

/**
 * @internal
 */
interface RangeProps {
  id: string;
  title: string;
  min: number;
  max: number;
  valueMin: number;
  valueMax: number;
  unitConverter: number;
  enabled: boolean;
  compact: boolean;
  onChange?: ( range: number[] ) => void;
  onEnable?: ( enFlag: boolean ) => void;
}

/**
 * @internal
 */
interface RangeState {
  valueMin?: number;
  valueMax?: number;
  precision?: number;
  enabled?: boolean;
}

/**
 * @internal
 */
export class TRange extends React.Component< RangeProps, RangeState > {

  _slider : JQuery;

  constructor( p: RangeProps ) {
    super(p);
    this.state = {
      valueMin: p.valueMin,
      valueMax: p.valueMax,
      enabled: p.enabled === undefined ? false : p.enabled
    };
  }

  setPrecision( max: number, min: number ) {
    var precision = 0;
    var range = (max - min) / 100;
    if ( range < 0.1 )
      precision = 2;
    else if ( range < 1 )
      precision = 1;
    this.setState({ precision: precision });
    return precision;
  }
  componentWillUnmount() {
    $('#' + this.props.id).slider( 'destroy' );
  }

  componentDidMount() {
    var self = this;
    var precision = this.setPrecision(this.props.max, this.props.min);

    this._slider = $('#' + this.props.id).slider({
    //this._slider = new Slider(`#${this.props.id}`, {
      range: true,
      tooltip: 'hide',
      focus: false,
      min: this.props.min,
      max: this.props.max,
      value:[ this.state.valueMin, this.state.valueMax ],
      step: (this.props.max - this.props.min) / 100,
      precision: precision,
      enabled: this.props.enabled
    });

    this._slider.on('change', ( c: any ) => { // use 'change' event instead of 'slide'  I think the API might have changed
      if ( ! this.state.enabled )
        return;
      self.setState({
        valueMin:  Math.min(c.value.newValue[0],c.value.newValue[1]),
        valueMax:  Math.max(c.value.newValue[0],c.value.newValue[1])
      });
      if ( self.props.onChange)
        self.props.onChange( c.value.newValue );
    });
  }

  componentWillReceiveProps( p:RangeProps ) {
    var precision = this.setPrecision( p.max, p.min);
    var newEnableFlag = this.state.enabled;
    if ( p.enabled !== undefined) {
      newEnableFlag = p.enabled;
    }
    $('#' + this.props.id).slider({
          min: p.min,
          max: p.max,
          value: [ p.valueMin, p.valueMax ],
          step: (p.max - p.min) / 100,
        });
    this.setState({
      valueMin: p.valueMin,
      valueMax: p.valueMax,
      enabled: newEnableFlag });
  }

  handleEnableSwitch() {
    var newEnableFlag = !this.state.enabled;
    this.setState({ enabled: newEnableFlag });
    if ( newEnableFlag )
      // this._slider.enable();
      this._slider.slider('enable');
    else
      // this._slider.disable();
      this._slider.slider('disable');
    if ( this.props.onEnable )
       this.props.onEnable(newEnableFlag);
  }

  render() {
    var distRangeValue = (<span>
                            {(this.state.valueMin * this.props.unitConverter).toFixed(this.state.precision)}
                            •
                            {(this.state.valueMax * this.props.unitConverter).toFixed(this.state.precision)}
                          </span>);
    var formClass = classNames({
      'form-group slider-group' : true,
      'enabled': this.state.enabled,
      'compact': this.props.compact
    });
    var switchClass = classNames({
      'fa fa-lg': true,
      'fa-toggle-on': this.state.enabled,
      'fa-toggle-off': !this.state.enabled
    });
    var labelClass = classNames({
      'field slider-label': true,
      'compact': this.props.compact
    });
    var sliderClass = classNames({
      'slider-control': true,
      'compact': this.props.compact
    });
    return (<div className={formClass} >
              <i className={switchClass} onClick={this.handleEnableSwitch.bind(this)}></i>
              <label className={labelClass} >{this.props.title}</label >
              <div className={sliderClass}>
                <input type="range" className="form-control" id={this.props.id} />
              </div>
              <span className="label label-primary">{distRangeValue}</span>
           </div>);
  }

}

// ========================== <TColor> =====================================================

/**
 * @internal
 */
interface TColorProps {
  title: string;
  value?: string;
  enabled: boolean;
  edit?:boolean;
  onChange?: (c: string) => void;
  onShow: ( flag: boolean ) => void;
}

/**
 * @internal
 */
interface TColorState {
  value?: string;
  edit?: boolean;
}

/**
 * @internal
 */
export class TColor extends React.Component< TColorProps, TColorState > {
  constructor( p:TColorProps ) {
    super(p);
    this.state = {
      value: p.value,
      edit: p.edit
    };
  }

  componentWillReceiveProps( p:TColorProps ) {
    this.setState({value: p.value, edit: p.edit });
  }

  handleToggleEdit() {
    this.props.onShow(!this.state.edit);
    this.setState({ edit: !this.state.edit});
  }

  handleChange( c ) {
    this.setState({ value: c.hex, edit: false });
    this.props.onShow(false);
    if ( this.props.onChange)
      this.props.onChange(c.hex);
  }

  handleClose( c ) {
    this.setState({ value: '#' + c.hex, edit: false });
    this.props.onShow(false);
    if ( this.props.onChange)
      this.props.onChange('#' + c.hex);
  }

  render() {
    var formClass = classNames({
      'form-group color-group' : true,
      'enabled': this.props.enabled
    });
    var pos: any = { position: 'absolute',
                top: '42px',
                left: '0px',
                zIndex: 1000
                //display: this.state.edit ? 'block': 'none'
              };
    var cpicker = <span/>;
    if ( this.state.edit )
      cpicker = <div style={pos}>
                  <ColorPicker color={this.state.value}
                               onChange={this.handleChange.bind(this)} />
                </div>;

    return (<div className={formClass}>
              <label className="field color-label compact">{this.props.title}</label >
              <button className="btn btn-default color-btn"  onClick={this.handleToggleEdit.bind(this)}>
                <span className="color" style={{ backgroundColor: this.state.value }}>&nbsp;</span>
                <i className="fa fa-caret-down"></i>
              </button>
              {cpicker}
           </div>);
  }
}

// ========================== <Switch> =====================================================


/**
 * @internal
 */
interface SwitchProps {
  id: string;
  onLabel:string;
  offLabel:string;
  width?: number|string;
  on?: boolean;
  enabled?: boolean;
  onChange?: ( nv: boolean ) => void;
}

/**
 * @internal
 */
interface SwitchState {
  on?: boolean;
  width?: number|string;
  enabled?: boolean;
}

/**
 * @internal
 */
export class Switch extends React.Component< SwitchProps, SwitchState > {

  constructor( p: SwitchProps ) {
    super(p);
    this.state = {
      on: p.on || false,
      width: p.width || 200,
      enabled: p.enabled !== undefined ? p.enabled : true
    };
  }

  componentDidMount() {
    var self = this;
    var $sw = $('#' + this.props.id);
    $sw.bootstrapSwitch('state', this.state.on );
    // $sw.bootstrapSwitch('disabled', !this.props.enabled );
    //$sw.bootstrapSwitch('handleWidth', 10 );
    $sw.bootstrapSwitch('labelWidth', 0 );
    /*
    $sw.bootstrapSwitch('onText', this.props.onLabel );
    $sw.bootstrapSwitch('offText', this.props.offLabel );
    $sw.bootstrapSwitch('size', 'small' );
    */

     $sw.on('switchChange.bootstrapSwitch', (event, state) => {
      if ( self.props.onChange ) // && self.state.on!==state)
        //  self.setState( { on: state });
         self.props.onChange(state);
     });
  }

  componentWillReceiveProps( p: SwitchProps ) {
    var self = this;
    var changeOnState = this.state.on !== p.on;
    this.setState({ enabled: p.enabled, on: p.on, width: p.width });
    if ( changeOnState ) {
      var $sw = $('#' + this.props.id);
      _.defer( () => {
        $sw.bootstrapSwitch('state', self.state.on );
      });
    }
    if( p.enabled !== this.props.enabled ) { // HL added
      var $sw = $('#' + this.props.id);
      $sw.bootstrapSwitch('disabled', !p.enabled );
    }
  }

  render() {
    const { enabled } = this.props;
    return (<input type="checkbox"
                   disabled={ enabled === undefined ? false : !enabled }
                   id={this.props.id}
                   data-toggle="toggle"
                   data-handle-width={this.state.width}
                   data-on-text={this.props.onLabel}
                   data-off-text={this.props.offLabel}
                   data-size="small"
                   defaultChecked={true}
                  />);
  }

}


// ================================= <TvError> =================================

/**
 * @internal
 */
interface TvErrorProps {
  error: site.ServerError;
}

/**
 * @internal
 */
interface TvErrorState {
  showStack: boolean;
}

/**
 * @internal
 */
export class TvError extends React.Component<TvErrorProps,TvErrorState> {
  constructor( p: TvErrorProps) {
    super(p);
    this.state = {
      showStack: false
    };
  }

  render() {
    const { error } = this.props;
    const stackiconclass = classNames({
      'fa': true,
      'fa-chevron-right': !this.state.showStack,
      'fa-chevron-down': this.state.showStack,
    });
    const stackclass = classNames({
      'stack': true,
      'closed': !this.state.showStack,
    });
    if ( error.error ) {
      const stackInfo = _.map( error.error.stack, (sm, idx) => <li key={idx}>{sm}</li> );
      return   <div className="container">
                <div className="alert alert-danger" role="alert">
                  <h3>
                    <i className="fa fa-warning"></i>&nbsp;
                    {error.error.name}
                  </h3>
                  <p>
                    {error.error.message}
                  </p>
                  <h4 onClick={() => this.setState({ showStack: !this.state.showStack})}><i className={stackiconclass}></i> show stack</h4>
                  <div className={stackclass}>
                    <ul>
                      {stackInfo}
                    </ul>
                  </div>
                </div>
              </div>;
    }
    else {
      return   <div className="container">
                <div className="alert alert-danger" role="alert">
                  <p>
                    <i className="fa fa-warning"></i>&nbsp;
                    {error}
                  </p>
                </div>
              </div>;
    }
  }
}

interface AdminWarningProps {
  dict: site.LocaleDictionary;
  show: boolean;
  message?: string;
  details?: string;
  link?: string;
  linkdesc?: string;
}
interface AdminWarningState {
  show?: boolean;
}

/**
 * A more complex warning message to be used in admin sections
 *
 * @internal
 * @class AdminWarning
 * @extends {React.Component<AdminWarningProps, AdminWarningState>}
 */
export class AdminWarning extends React.Component<AdminWarningProps,AdminWarningState> {
  constructor( p: AdminWarningProps) {
    super(p);
    this.state = {
      show : p.show
    };
  }

  renderLink() {
    const { dict, link, linkdesc } = this.props;
    if ( link || dict['link'] ) {
      return <p>
        <a href={link || dict['link']}>{linkdesc || dict['linkdesc']}</a>
      </p>;
    }
    else
      return <span/>;
  }

  renderDetails() {
    const { dict, details } = this.props;
    if ( details || dict['details'] ) {
      return <p>
        {details || dict['details']}
      </p>;
    }
    else {
      return <span/>;
    }

  }

  render() {
    const { dict, message } = this.props;
    return <Popup id="licWarning"
        dict={dict}
        show={this.state.show}
        okEnabled={true}
        cancelEnabled={false}
        header={dict['title']}
        onCancel={ () => this.setState( { show: false } ) }
        onOk={ () => this.setState( { show: false } ) } >
      <WarningMsg message={message || dict['message']} show={true} >
        {this.renderDetails()}
        {this.renderLink()}
      </WarningMsg>
    </Popup>;
  }
}
