import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Store, StoreContext } from '../lib/Store';
import { asyncComponent } from './asynccomponent';
import { RouteCatcher } from '../components/RouteCatcher';
import * as tvgui from '../common.ui/popup';
import { renderRedirectToReturnUrl } from '../lib/session';
import '../../less/site.less';
import { isService } from '../common.ui/tvgsite';

const ViewerLoader = asyncComponent(() => import('./viewerloader'), 'ViewerLoader');
const LocationsLoader = asyncComponent(() => import('../site.ui/siteslistpage'), 'LocationsLoader');
const ScansListLoader = asyncComponent(() => import('../site.ui/scanslistpage'), 'ScansListLoader');
const SitePageLoader = asyncComponent(() => import('../site.ui/sitepage'), 'SitePageLoader');

const helpLazyFn = () => import('../site.ui/helppage');
const adminLazyFn = () => import('../site.ui/admin');
const logspageLazyFn = () => import('../site.ui/logspage');

const LicensePage = asyncComponent(helpLazyFn, 'LicensePage');
const AdminHelpPage = asyncComponent(helpLazyFn, 'AdminHelpPage');
const UserHelpPage = asyncComponent(helpLazyFn, 'UserHelpPage');
const AdminRootPage = asyncComponent(adminLazyFn, 'AdminRootPage');
const AdminCustomizePage = asyncComponent(adminLazyFn, 'AdminCustomizePage');
const AdminPortalsPage = asyncComponent(adminLazyFn, 'AdminPortalsPage');
const AdminUsersPage = asyncComponent(adminLazyFn, 'AdminUsersPage');
const AdminBackupsPage = asyncComponent(adminLazyFn, 'AdminBackupsPage');
const AdminAppsPageLoader = asyncComponent(adminLazyFn, 'AdminAppsPageLoader');
const AdminLogsPage = asyncComponent(adminLazyFn, 'AdminLogsPage');
const AdminSMTPPage = asyncComponent(adminLazyFn, 'AdminSMTPPage');
const AdminUpdatePage = asyncComponent(adminLazyFn, 'AdminUpdatePage');
const AdminLicensePage = asyncComponent(adminLazyFn, 'AdminLicensePage');
const PoliciesPage = asyncComponent(() => import('../site.ui/policies'), 'PoliciesPage');
const ConsentPage = asyncComponent(() => import('../site.ui/consent'), 'ConsentPage');
const ResetUserPage = asyncComponent(() => import('../site.ui/resetuser'), 'ResetUserPage');
const ImportQueuePage = asyncComponent(logspageLazyFn, 'ImportQueuePage');
const ImportLogPage = asyncComponent(logspageLazyFn, 'ImportLogPage');
const UploadPage = asyncComponent(() => import('./uploader/UploadPage'), 'UploadPage');
const ScanInfoPageLoader = asyncComponent(() => import('../site.ui/scaninfopage'), 'ScanInfoPageLoader');
const WelcomePage = asyncComponent(() => import('./welcome'), 'WelcomePage');
const AuthorizePageLoader = asyncComponent(() => import('./authorize'), 'AuthorizePageLoader');
const UserSettingsPage = asyncComponent(() => import('./usersettings'), 'UserSettingsPage');
const EmbedPage = asyncComponent(() => import('../embed'), 'EmbedPage');
const CompanyInfoPage = asyncComponent(() => import('./companyinfo'), 'CompanyInfoPage');

const Loading = () => null;

let _i = 0;

interface Props {
  initialData?: any;
}
export class App extends React.Component<Props> {

  state = {
    storeHolder: {
      store: null as Store,
      _i,
    }
  };

  constructor(props: any) {
    super(props);
    const store = new Store();
    store.update = this.onStoreUpdate;
    store.flash.update = this.onStoreUpdate;
    store.root = props.initialData;
    this.state.storeHolder.store = store;
    this.state.storeHolder._i = (_i += 1);
    window['store'] = store;
    this.loadData();
  }

  componentWillUnmount() {
    this.state.storeHolder.store.dispose();
    const instStore = this.state.storeHolder.store;
    const globalStore = window['store'];
    if (globalStore === instStore) {
      window['store'] = null;
    }
  }

  onStoreUpdate = () => {
    const {store} = this.state.storeHolder;
    const storeHolder = {
      store,
      _i: (_i += 1),
    };
    this.setState({storeHolder});
    document.title =  isService( store.root.license ) ? `TruView Cloud ${store.root.version}` : `TruView Enterprise ${store.root.version}`;
  };

  loadData = async () => {
    await this.state.storeHolder.store.fetchRoot();
  };

  render() {
    const { store } = this.state.storeHolder;
    const { root } = store;
    if (!root) return null;
    const {user, license} = root;

    // pre-flight check flags
    const licenseProblem = !!(user && license && license.type !== 'cloud' && (!license.generator || license.users === 0) && user.role === 'admin');
    const resetuser = !!(user && !(user.resetuser === false));
    const needsconsent = !!root.needsconsent;
    const needsterms = !root.termsaccepted;
    const needscompanyinfo = !!user && (user.role === 'admin') && ( !root.custom.ownerinfo.name || !root.custom.ownerinfo.privacyPolicyUrl || !root.custom.ownerinfo.contactEmail );
    
    return (
      <StoreContext.Provider value={store}>
        <BrowserRouter>
          <RouteCatcher>
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/policies" render={(props) => <PoliciesPage {...props} store={store} />} />
                <Route exact path="/license" render={(props) => <LicensePage {...props} store={store} />} />
                {/* Terms accepted */}
                {needsterms && <Redirect to="/license" />}

                {/* Anonymous-allowed pages */}
                <Route exact path="/embed/map/:mapId" render={(props) => <EmbedPage key={props.match.params.mapId } {...props} store={store} />} />
                <Route exact path="/embed/scan/:scanId" render={(props) => <EmbedPage key={props.match.params.scanId } {...props} store={store} />} />
                <Route exact path="/auth/authorize" render={(props) => <AuthorizePageLoader {...props} store={store} />} />
                {user ? <Redirect exact from="/welcome" to="/locations" /> :
                  <Route exact path="/welcome" render={(props) => <WelcomePage {...props} store={store} />} />}
                <Route exact path="/help" render={(props) => <UserHelpPage {...props} store={store} />} />
                <Route exact path="/help/admin" render={(props) => <AdminHelpPage {...props} store={store} />} />
                {!user && <Route exact path="/scan/:scanId" render={(props) => <ViewerLoader key={props.match.params.scanId } {...props} />} />}
                {!user && <Route exact path="/site/:siteId" render={(props) => <SitePageLoader key={props.match.params.siteId } {...props} store={store} />} />}
                {!user && <Route exact path="/scan/info/:scanId" render={(props) => <ScanInfoPageLoader key={props.match.params.scanId } {...props} store={store} />} />}
                {!user && <Redirect to="/welcome" />}

                {/* Reset */}
                <Route exact path="/resetuser" render={(props) => <ResetUserPage {...props} store={store} />} />
                {resetuser && <Redirect to="/resetuser" />}

                {/* Consent */}
                <Route exact path="/login/consent" render={(props) => <ConsentPage {...props} store={store} />} />
                {needsconsent && <Redirect to="/login/consent" />}

                {/* Company info */}
                <Route exact path="/companyinfo" render={(props) => <CompanyInfoPage {...props} store={store} />} />
                {needscompanyinfo && <Redirect to="/companyinfo" />}

                {renderRedirectToReturnUrl()}

                {/* Admin */}
                <Route exact path="/admin" render={(props) => <AdminRootPage {...props} store={store} />} />
                <Route exact path="/admin/customize" render={(props) => (
                  <AdminCustomizePage
                    {...props}
                    store={store}
                    locale={store.root.locale}
                    admincustomizeDict={store.root.admincustomizeDict}
                    usersDict={store.root.usersDict}
                    adminDict={store.root.adminDict}
                    settings={store.root.custom}
                    license={store.root.license}
                  />
                )} />
                <Route exact path="/admin/portals" render={(props) => <AdminPortalsPage {...props} store={store} />} />
                <Route exact path="/admin/backups" render={(props) => <AdminBackupsPage {...props} store={store} />} />
                <Route exact path="/admin/logs" render={(props) => (
                  <AdminLogsPage
                    {...props}
                    store={store}
                    usersDict={store.root.usersDict}
                    adminDict={store.root.adminDict}
                    adminlogsDict={store.root.adminlogsDict}
                  />
                )} />
                <Route exact path="/admin/smtp" render={(props) => (
                  <AdminSMTPPage
                    {...props}
                    store={store}

                  />
                )} />
                <Route exact path="/admin/update" render={(props) => <AdminUpdatePage {...props} store={store} />} />
                <Route exact path="/admin/license" render={(props) => <AdminLicensePage {...props} store={store} />} />
                <Route exact path="/admin/apps" render={(props) => <AdminAppsPageLoader {...props} store={store} />} />
                <Route exact path="/admin/users/:portal" render={(props) => <AdminUsersPage key={props.match.params.portal }{...props} store={store} />} />
                {licenseProblem && <Redirect to="/admin/license" />}

                {/* Main */}
                <Route exact path="/dataset/import/queue" render={(props) => <ImportQueuePage {...props} store={store} />} />
                <Route exact path="/dataset/uploader" render={(props) => <UploadPage {...props} store={store} />} />
                <Route exact path="/scans" render={(props) => <ScansListLoader {...props} store={store} />} />
                <Route exact path="/locations" render={(props) => <LocationsLoader {...props} store={store} />} />
                <Route exact path="/scan/:scanId" render={(props) => <ViewerLoader key={props.match.params.scanId } {...props} />} />
                <Route exact path="/site/:siteId" render={(props) => <SitePageLoader key={props.match.params.siteId } {...props} store={store} />} />
                <Route exact path="/scan/info/:scanId" render={(props) => <ScanInfoPageLoader key={props.match.params.scanId } {...props} store={store} />} />
                <Route exact path="/user/:userId" render={(props) => <UserSettingsPage key={props.match.params.userId } {...props} store={store} />} />

                {/* Fallback */}
                <Redirect to="/welcome" />
              </Switch>

              <tvgui.Popup
                id="flashNotice"
                dict={store.root.locationsDict}
                show={!!store.flash.notice}
                okEnabled={true}
                cancelEnabled={false}
                header="Notice"
                icon="fa-check-circle text-success fa-lg"
                onOk={() => store.flash.clear()}
              >
                {store.flash.notice && (
                  <div className="alert alert-success">
                    <h4>{store.flash.notice.text}</h4>
                    <p>{store.flash.notice.dataText}</p>
                  </div>
                )}
              </tvgui.Popup>

              <tvgui.Popup
                id="flashError"
                dict={store.root.locationsDict}
                show={!!store.flash.error}
                okEnabled={true}
                cancelEnabled={false}
                header="Error"
                icon="fa-warning text-danger fa-lg"
                onOk={() => store.flash.clear()}
              >
                {store.flash.error && (
                  <div className="alert alert-danger">
                    <h4>{store.flash.error.text}</h4>
                    <p>{store.flash.error.dataText}</p>
                  </div>
                )}
              </tvgui.Popup>
            </React.Suspense>
          </RouteCatcher>
        </BrowserRouter>
      </StoreContext.Provider>
    );
  }
}
