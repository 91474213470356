import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RouterContext } from '../lib/RouterContext';

// polyfills

if (!Element.prototype.matches) {
  // @ts-ignore
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const URL_ROOT = `${window.location.protocol}//${window.location.host}`;

const SKIP_REGEXP_PATHS = ['^/admin/backup/', '^/site/data/'];

class RouterCatcherBase extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    document.addEventListener('click', this.onAHrefClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onAHrefClick, false);
  }

  /**
   * Checks any click for self or parent a[href].  If href is local, intercepts and
   * pushes on react-router.  Otherwise lets pass through as normal.
   */

  onAHrefClick = e => {
    // @ts-ignore
    const a: Element = e.target.closest('a[href]');
    if (!a) return;
    const href = a.getAttribute('href');
    if (!href) return;
    if (href[0] !== '/' && href.indexOf(URL_ROOT) !== 0) return;
    for (const skip of SKIP_REGEXP_PATHS) {
      if (href.match(skip)) return;
    }
    // console.log('SPA proxy internal', href);
    e.preventDefault();
    this.props.history.push(href);
  };

  render() {
    return this.props.children;
  }
}

export const RouteCatcher: React.SFC<any> = (props) => (
  <RouterContext>{routeProps => <RouterCatcherBase {...routeProps} {...props} />}</RouterContext>
);
