import * as React from 'react';
import { getRequest2 } from '../common.ui/tvgsite';

function recordReturnUrl() {
  try {
    if (window.location.pathname === '/welcome') return;
    const {href} = window.location;
    localStorage.setItem('returnUrl', href);
  } catch (err) {
    console.warn('recordReturnUrl error', err);
  }
};

function getReturnUrl() {
  try {
    return localStorage.getItem('returnUrl') || '';
  } catch (err) {
    console.warn('getReturnUrl error', err);
  }
  return '';
};

function clearReturnUrl() {
  try {
    localStorage.removeItem('returnUrl');
  } catch (err) {
    console.warn('clearReturnUrl error', err);
  }
}

const RedirectToReturnUrl: React.SFC<{to: string}> = (props) => {
  clearReturnUrl();
  window.location.href = props.to;
  return null;
};

export function handleLoginWithReturnUrl(e: any) {
  if (e) e.preventDefault();
  recordReturnUrl();
  window.location.href = '/welcome';
}

export async function handleLogout(e: any) {
  if (e) e.preventDefault();
  await getRequest2('/login').catch();
  window.location.href = '/welcome';
}

export function renderRedirectToReturnUrl() {
  const returnUrl = getReturnUrl();
  return returnUrl ? <RedirectToReturnUrl to={returnUrl} /> : null;
}

